import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../components/Text';
import Heading from '../../components/Heading';
import Image from '../../components/Image';
import Container from '../../components/Container';
import WorkingBlock from '../../components/WorkingBlock';
import { SectionHeader } from '../global.style';
import SectionWrapper, { FeatureWrapper } from './working.style';
import { useTranslation } from 'react-i18next';

const WorkingSection = () => {
  const { t } = useTranslation('working');

  const items = [
    {
      id: 1,
      image: require('../../assets/images/working1.png'),
      title: t('item1.header'),
      description: t('item1.description'),
      additionalContent: t('item1.footer'),
    },
    {
      id: 2,
      image: require('../../assets/images/working2.png'),
      title: t('item2.header'),
      description: t('item2.description'),
      additionalContent: t('item2.footer'),
    },
    {
      id: 3,
      image: require('../../assets/images/working3.png'),
      title: t('item3.header'),
      description: t('item3.description'),
      additionalContent: t('item3.footer'),
    },
    {
      id: 4,
      image: require('../../assets/images/working4.png'),
      title: t('item4.header'),
      description: t('item4.description'),
      additionalContent: t('item4.footer'),
    },
  ];

  return (
    <SectionWrapper id='working'>
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as='h5' content={t('main.slogan')} />
            {/* <Heading content={t('main.title')} /> */}
          </Fade>
        </SectionHeader>
        <FeatureWrapper>
          {items.map((item) => (
            <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
              <WorkingBlock
                style={{ '--color': `${item.color}` }}
                icon={
                  <Fragment>
                    <Image src={item.image} alt={item.title} />
                  </Fragment>
                }
                iconPosition='left'
                title={<Heading as='h3' content={item.title} />}
                description={<Text content={item.description} />}
                // additionalContent={<Text content={item.additionalContent} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default WorkingSection;
