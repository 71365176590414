import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../components/Box';
// import Text from '../../components/Text';
import Heading from '../../components/Heading';
import Button from '../../components/Button';
// import Input from '../../components/Input';
import Container from '../../components/Container';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { lineChart } from 'react-icons-kit/fa/lineChart';
import { Icon } from 'react-icons-kit';

import NewsletterWrapper, { ContactFormWrapper, SurveyWrapper, InputEmail } from './newsletter.style';

const Newsletter = ({ sectionWrapper, textArea, buttonArea, buttonStyle, title, description }) => {
  const { t } = useTranslation('home');

  const url = 'https://app.us19.list-manage.com/subscribe/post?u=6fb0716b672c864bddba38e0c&amp;id=1ef29cde6b';

  const onSurveyButtonClick = () => {
    window.open('https://www.umfrageonline.com/s/9a0185f');
  };

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
      });

    return (
      <div
        style={{
          borderRadius: 2,
          padding: 10,
        }}
      >
        {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
        {status === 'error' && <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />}
        {status === 'success' && <div style={{ color: 'green' }} dangerouslySetInnerHTML={{ __html: message }} />}
        {/* <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '470px',
            maxWidth: '100%',
            justifyContent: 'center',
          }}
        > */}
        <ContactFormWrapper>
          <InputEmail ref={(node) => (email = node)} type='email' placeholder={t('newsletter.email')} />
          {/* <Input
            inputType='email'
            label={t('newsletter.email')}
            iconPosition='right'
            isMaterial={true}
            className='email_input'
            ref={node => (email = node)}
          /> */}
          {/* <br /> */}
          <Button {...buttonStyle} title={t('newsletter.button')} onClick={submit} />
          {/* <button style={{ fontSize: '2em', padding: 5 }} onClick={submit}>
            Submit
          </button> */}
        </ContactFormWrapper>
        {/* //</div> */}
      </div>
    );
  };

  return (
    <Box {...sectionWrapper} as='section' id='contact'>
      <NewsletterWrapper>
        <Container>
          <Box {...textArea}>
            <Heading as='h1' content={t('newsletter.title')} {...title} />
            <Heading content={t('newsletter.header')} {...title} />
            {/* <Text content={t('newsletter.description')} {...description} /> */}
          </Box>
          <Box {...buttonArea}>
            {/* <ContactFormWrapper>
              <Input
                inputType='email'
                label={t('newsletter.email')}
                iconPosition='right'
                isMaterial={true}
                className='email_input'
              />
              <Button {...buttonStyle} title={t('newsletter.button')} />  
               </ContactFormWrapper> */}
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm status={status} message={message} onValidated={(formData) => subscribe(formData)} />
              )}
            />
            {/* <SurveyWrapper {...textArea}>
              <Button
                className='btnSurvey'
                // variant='textButton'
                // colors='error'
                onClick={onSurveyButtonClick}
                title={t('newsletter.survey')}
                icon={<Icon size={24} icon={lineChart} />}
                iconPosition='right'
              />
            </SurveyWrapper> */}
          </Box>
        </Container>
      </NewsletterWrapper>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '0', '0'],
  },
  buttonArea: {
    zIndex: 1,
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    maxWidth: ['100%', '400px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },

  buttonStyle: {
    type: 'button',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    color: 'rgb(15, 33, 55)',
    fontSize: '16px',
    letterSpacing: '-0.1px',
    paddingLeft: '16px',
    paddingRight: '16px',
    textTransform: 'capitalize',
  },
};

export default Newsletter;
