import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.section`
  margin-top: 50px;
  background-image: linear-gradient(to bottom right, rgba(246, 248, 252, 0), rgba(27, 148, 143, 1));
  padding: 30px 0 40px;
  // margin-bottom: 80px;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    padding: 80px 0 200px;
  }
  @media only screen and (max-width: 1000px) {
    padding: 80px 0 80px;
  }
  @media only screen and (max-width: 667px) {
    padding: 80px 0;
  }
  .container {
    // min-height: 400px;

    > header {
      //max-width: 380px;
      @media only screen and (max-width: 480px) {
        max-width: 280px;
      }
    }
  }
  h5 {
    font-size: 36px;
    text-align: center;
  }
`;

export const TabWrapper = styled.section`
  flex-direction: row;
  display: flex;
  #healthImage {
    height: 450px;
    @media only screen and (max-width: 667px) {
      height: 300px;
    }
  }
  @media only screen and (max-width: 667px) {
    flex-direction: column;
  }
`;

export const SectionItems = styled.div`
  flex-direction: column;
`;

export const ItemDiv = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  h2 {
    padding: 0;
    margin: 0;
  }
`;

export default SectionWrapper;
