import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from '../../components/ScrollSpyMenu';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
// import { search } from 'react-icons-kit/feather/search';
import Logo from '../../components/Logo';
import Button from '../../components/Button';
import Container from '../../components/Container';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style'; //Search
import LogoImage from '../../assets/images/yushLogoWhite.png';
import LogoImageAlt from '../../assets/images/yushLogo.png';
import EnglishIcon from '../../assets/images/english-language.png';
import GermanIcon from '../../assets/images/german-language.png';
import i18next from 'i18next';

const Navbar = () => {
  const [language, setLangauge] = useState(i18next.language);
  const [langText, setLangText] = useState(language.startsWith('de') ? 'English' : 'Deutsch');
  const [imgSrc, setImgSrc] = useState(language.startsWith('de') ? EnglishIcon : GermanIcon);
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label_en
            label_de
            path
            offset
          }
        }
      }
    }
  `);
  const { navMenu } = data.appModernJson.navbar;
  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  // const { t } = useTranslation('home');

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () => setState({ ...state, searchToggle: false }));

  const onLanguageChange = () => {
    if (langText === 'English') {
      i18next.changeLanguage('en');
      setLangauge('en');
      setLangText('Deutsch');
      setImgSrc(GermanIcon);
    } else {
      i18next.changeLanguage('de');
      setLangauge('de');
      setLangText('English');
      setImgSrc(EnglishIcon);
    }
  };

  const toggleHandler = (type) => {
    if (type === 'search') {
      setState({
        ...state,
        search: '',
        searchToggle: !state.searchToggle,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  // const handleOnChange = event => {
  //   setState({
  //     ...state,
  //     search: event.target.value,
  //   });
  // };

  // const handleSearchForm = event => {
  //   event.preventDefault();

  //   if (state.search !== '') {
  //     console.log('search data: ', state.search);

  //     setState({
  //       ...state,
  //       search: '',
  //     });
  //   } else {
  //     console.log('Please fill this field.');
  //   }
  // };

  const scrollItems = [];

  navMenu.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };

  return (
    <NavbarWrapper className='navbar'>
      <Container>
        <Logo href='/' logoSrc={LogoImage} title='Yush Application' className='main-logo' />
        <Logo href='/' logoSrc={LogoImageAlt} title='Yush Application' className='logo-alt' />
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <ScrollSpyMenu className='menu' menuItems={navMenu} offset={-84} />
          {/* end of main menu */}
          {/* <Search className='search' ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input type='text' value={state.search} placeholder='Enter your keyword' onChange={handleOnChange} />
            </form>
            <Button
              className='text'
              variant='textButton'
              icon={<Icon icon={state.searchToggle ? x : search} />}
              onClick={() => toggleHandler('search')}
            />
          </Search> */}
          {/* end of search */}
          {/* 
          <AnchorLink href='#trail' offset={84}>
            <Button title='Try for Free' />
          </AnchorLink> */}
          {/* <LanguageSelector />
          
          <div> {t('navbar.about')}</div> */}
          {/* <h1 onClick={this.changeTitle}>test</h1>; */}
          {/* <LanguageSwitcher /> */}
          <Button
            className='menubar'
            icon={
              state.mobileMenu ? (
                <Icon className='bar' icon={x} />
              ) : (
                <Fade>
                  <Icon className='close' icon={menu} />
                </Fade>
              )
            }
            color='#0F2137'
            variant='textButton'
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy className='menu' items={scrollItems} offset={-84} currentClassName='active'>
            {navMenu.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <AnchorLink href={menu.path} offset={menu.offset} onClick={handleRemoveMenu}>
                  {language.includes('de') ? menu.label_de : menu.label_en}
                </AnchorLink>
              </li>
            ))}
            <Button
              className='btnLanguage'
              title={langText}
              onClick={onLanguageChange}
              iconPosition='left'
              icon={<img src={imgSrc} />}
            />
          </Scrollspy>
          {/* <Button title='Try for Free' /> */}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
