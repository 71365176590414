import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from '../theme';
import { ResetCSS } from '../assets/css/style';
import Sticky from 'react-stickynode';
import '../utils/i18n';
import Navbar from '../containers/Navbar';
import Banner from '../containers/Banner';
import AppSlider from '../containers/AppSlider';
import WorkingSection from '../containers/Working';
import DashboardFeatures from '../containers/Dashboard';
import ProductSlide from '../containers/ProductSlide';
import DesignedAndBuilt from '../containers/DesignedAndBuilt';
import KeyPoints from '../containers/KeyPoints';
import PricingPolicy from '../containers/PricingPolicy';
import TeamPortfolio from '../containers/TeamPortfoilo';
import Testimonial from '../containers/Testimonial';
import FeatureTab from '../containers/FeatureTab';
import HealthTab from '../containers/HealthTab';
import Newsletter from '../containers/Newsletter';
import Footer from '../containers/Footer';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/global.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <SEO title='Yush! Die mobile app für deine effiziente Lebenmittelplanung' />
      <Modal />
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
          <Navbar />
        </Sticky>
        <ContentWrapper>
          <Banner />
          <WorkingSection />
          <HealthTab />
          {/*  <FeatureTab /> */}
          {/*  <AppSlider />
          <DashboardFeatures />
          <Testimonial />
          <ProductSlide />
          <KeyPoints />
          <DesignedAndBuilt />
          <PricingPolicy />
          <TeamPortfolio /> */}
          {/* <Newsletter /> */}
        </ContentWrapper>
        <Footer />
      </AppWrapper>
    </ThemeProvider>
  );
}
