import styled from 'styled-components';
import BannerBG from '../../assets/images/bottom.png';
import { themeGet } from '@styled-system/theme-get';

const NewsletterWrapper = styled.div`
  position: relative;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 80px;

  @media (max-width: 1220px) {
    padding: 35px 40px;
  }
  @media (max-width: 575px) {
    padding: 35px 20px;
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 990px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  h1 {
    font-size: 38px;
    line-height: 55px;
    font-weight: 700;
    margin-bottom: 24px;
    text-align: center;
    @media only screen and (max-width: 1366px) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }
`;

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 30px;

  @media (max-width: 1220px) {
    padding: 15px 40px;
  }
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
  .surveyClass {
    font-size: 14px;
    line-height: 55px;
    @media only screen and (max-width: 1366px) {
      font-size: 12px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }

  .btnSurvey {
    background-color: #ffc107;
    color: white;
    border-radius: 12px;
    text-transform: none;
    &:hover {
      box-shadow: #1e2a4a 0px 12px 24px -10px;
    }
  }
`;
export const ContactFormWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 470px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1220px) {
    width: 420px;
  }
  @media (max-width: 575px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .email_input {
    flex-grow: 1;
    margin-right: 20px;

    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &.is-material {
      &.is-focus {
        label {
          font-size: 14px;
          color: #fff;
        }
        .highlight {
          background: #fff;
          height: 1px;
        }
      }
    }

    .highlight {
      background: rgba(255, 255, 255, 0.4);
    }

    input {
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 10px 15px;
      border-color: #dadada;
      @media (max-width: 575px) {
        height: 48px;
      }
    }

    label {
      font-size: 17px;
      color: #fff;
      font-weight: 400;
      padding-left: 10px;
      top: 5px;
      pointer-events: none;
    }
  }
  .reusecore__button {
    background-color: #ffffff;
    color: rgb(15, 33, 55);
    font-size: 16px;
    letter-spacing: -0.1px;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
    text-transform: capitalize;
    &:hover {
      box-shadow: #1e2a4a 0px 12px 24px -10px;
    }
  }
`;

export const InputEmail = styled.input`
display: block;
width: 100%;
background: transparent;
color: ${themeGet('colors.textColor', '#484848')};
box-shadow: none;
box-sizing: border-box;
border: 1px solid ${themeGet('colors.inactiveIcon', '#ebebeb')};
transition: border-color 0.2s ease;
&:focus {
  outline: none;
  border-color: ${themeGet('colors.primary', '#028489')};
}
font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 10px 15px;
      border-color: #dadada;
  }
  ::placeholder { 
    color: #fff;
    opacity: 1; /* Firefox */
  }
  border-radius: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0;
  padding-right: 100;
  flex-grow: 1;
  margin-right: 20px;

  @media (max-width: 575px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  
`;
export default NewsletterWrapper;
