import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Heading from '../../components/Heading';
import Container from '../../components/Container';
import { SectionHeader } from '../global.style';
import SectionWrapper, { TabWrapper, SectionItems, ItemDiv } from './healthTab.style';
import { useTranslation } from 'react-i18next';
import healthImage from '../../assets/images/healthImage.png';

const HealthTab = () => {
  const { t } = useTranslation('health');

  return (
    <SectionWrapper id='advantages'>
      <Container>
        {/* <SectionHeader> */}
        <Heading as='h5' content={t('main.slogan')} />
        <TabWrapper>
          <Image src={healthImage} alt='TooGoodForDustbin' id='healthImage' />
          <SectionItems>
            <ItemDiv>
              <Text as='h2' content={t('item1.header')} />
              <Text content={t('item1.description')} />
            </ItemDiv>
            <ItemDiv>
              <Text as='h2' content={t('item2.header')} />
              <Text content={t('item2.description')} />
            </ItemDiv>
            <ItemDiv>
              <Text as='h2' content={t('item3.header')} />
              <Text content={t('item3.description')} />
            </ItemDiv>
            <ItemDiv>
              <Text as='h2' content={t('item4.header')} />
              <Text content={t('item4.description')} />
            </ItemDiv>
          </SectionItems>
        </TabWrapper>
        {/*  </SectionHeader> */}
      </Container>
    </SectionWrapper>
  );
};

export default HealthTab;
