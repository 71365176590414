import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
// import { useStaticQuery, graphql } from 'gatsby';
// import { Icon } from 'react-icons-kit';
// import { playCircle } from 'react-icons-kit/fa/playCircle';
// import { openModal, closeModal } from '@redq/reuse-modal';
import Text from '../../components/Text';
import Image from '../../components/Image';
// import Button from '../../components/Button';
import Heading from '../../components/Heading';
// import Rating from '../../components/Rating';
import Container from '../../components/Container';
import BannerWrapper, {
  BannerContent,
  // RatingInfo,
  BannerImage,
  // ButtonGroup,
  VideoGroup,
  // VideoWrapper,
  // CustomerWrapper,
  // ImageWrapper,
} from './banner.style';
import { useTranslation } from 'react-i18next';
// import microsoft from '../../assets/images/envato-icon.png';
import bannerImg from '../../assets/images/homeScreenBanner4.png';
import downloadApple from '../../assets/images/downloadAppleStore.png';
import downloadGoogle from '../../assets/images/downloadGooglePlay.png';
import circleBorder from '../../assets/images/shape.svg';
import Toast from 'react-bootstrap/Toast';
// close button for modal
// const CloseModalButton = () => (
//   <Button
//     className='modalCloseBtn'
//     variant='fab'
//     onClick={() => closeModal()}
//     icon={<i className='flaticon-plus-symbol' />}
//   />
// );

// const ModalContent = () => (
//   <VideoWrapper>
//     <iframe title='Video' src='https://www.youtube.com/embed/8ME-QAlW6Ww' frameBorder='0' />
//   </VideoWrapper>
// );

const Banner = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     appModernJson {
  //       client {
  //         id
  //         title
  //         image {
  //           publicURL
  //         }
  //       }
  //     }
  //   }
  // `);
  // const { client } = data.appModernJson;
  // modal handler
  // const handleVideoModal = () => {
  //   openModal({
  //     config: {
  //       className: 'video-modal',
  //       disableDragging: true,
  //       default: {
  //         width: 'auto',
  //         height: 'auto',
  //         x: 0,
  //         y: 0,
  //       },
  //     },
  //     component: ModalContent,
  //     componentProps: {},
  //     closeComponent: CloseModalButton,
  //     closeOnClickOutside: true,
  //   });
  // };

  const { t } = useTranslation('home');
  // const [showAlert, setShowAlert] = useState(false);

  // const onSurveyButtonClick = () => {
  //   window.open('https://www.umfrageonline.com/s/9a0185f');
  // };

  const onApplePress = () => {
    window.open('https://apps.apple.com/de/app/yush/id1547039929');
  };

  const onGooglePress = () => {
    window.open('https://play.google.com/store/apps/details?id=app.yush');
  };

  return (
    <BannerWrapper id='home'>
      <Container>
        <BannerContent>
          {/* <Fade up>
            <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt='Microsoft' />
            </RatingInfo>
          </Fade> */}
          <Fade up delay={100}>
            <Heading as='h1' content='Yush!' />
            <Heading as='h2' content={t('banner.heading')} />
          </Fade>
          <Fade up delay={200}>
            <Text content={t('banner.subHeading')} />
          </Fade>
          {/* <Fade up delay={300}>
            <ButtonGroup>
              <Button className='primary' onClick={onSurveyButtonClick} title={t('banner.surveyButton')} />
              {/* <Button
                className='text'
                variant='textButton'
                icon={<Icon icon={playCircle} />}
                iconPosition='left'
                title='Watch Video'
              /> 
            </ButtonGroup>
          </Fade> */}
          <VideoGroup>
            <img src={downloadApple} alt='Apple' onClick={onApplePress} />
            <img src={downloadGoogle} alt='Google' onClick={onGooglePress} />
          </VideoGroup>
          {/* {showAlert && (
            <Toast onClose={() => setShowAlert(false)} show={showAlert} delay={5000} autohide>
              <Toast.Header closeButton={false}>
                <strong className='mr-auto'>Coming Soon!</strong>
              </Toast.Header>
              <Toast.Body>{t('banner.coming')} </Toast.Body>
            </Toast>
          )} */}
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt='Banner' />
          </Fade>
        </BannerImage>
      </Container>
      {/* <CustomerWrapper>
        <Text content='Trusted by companies like:' />
        <ImageWrapper>
          {client.map(item => (
            <Image key={`client-key${item.id}`} src={item.image.publicURL} alt={item.title} />
          ))}
        </ImageWrapper>
      </CustomerWrapper> */}
      <img className='bannerBottomShape' src={circleBorder} alt='Bottom Circle' />
    </BannerWrapper>
  );
};

export default Banner;
